import {EventBus} from "../events/EventBus";
import GoogleTracking from "./tracking/GoogleTracking";
import {WhitelabelEvent, WhitelabelEventWithPayload} from "../events/Events";
import GA4Tracking from "./tracking/GA4Tracking";
import {httpPostEventHandler, PostEvent} from "./http";
import {
    ALERT_BUTTON_CLICKED_EVENT_TYPE, AlertButtonClickedEventPayload,
    FAVORITE_ADDED_EVENT_TYPE, FAVORITE_REMOVED_EVENT_TYPE,
    FavoriteClickedEventPayload,
    FILTERS_MODAL_OPENED_EVENT_TYPE,
    FILTERS_UPDATED_EVENT_TYPE,
    FiltersModalOpenedEventPayload,
    FiltersUpdatedEventPayload,
    MARKET_STATS_CLICKED_EVENT_TYPE,
    MARKET_STATS_DROPDOWN_CLICKED_EVENT_TYPE,
    MARKET_STATS_VIEWED_EVENT_TYPE,
    MarketStatsClickedEventPayload,
    MarketStatsViewedEventPayload,
    PERMISSION_SEND_COMMUNICATIONS,
    PermissionSendCommunicationsEventPayload,
    SEARCH_SAVED_EVENT_TYPE,
    SearchSavedEventPayload,
    SECTION_CLICKED_EVENT_TYPE,
    SectionClickedEventPayload,
    SHARE_CLICKED_EVENT_TYPE,
    ShareClickedEventPayload,
    WEB_PUSH_ALLOWED_EVENT_TYPE,
    WebPushAllowedEventPayload
} from "../tracking/constants";
import {ShareTrackingProperties} from "../webcomponents/share/share.component";

let tracker: GoogleTracking | object;

let eventBus: EventBus;

let isInitiated: boolean = false;


interface TrackAnalyticsEventPayload {
    name: string | undefined,
}

interface TrackWebTrackingEventPayload {
    type: string,
    serpUrl: string,
    section?: string,
    pageViewId?: string,
    filters?: any,
    sectionName?: string,
    value?: string,
}

class TrackAnalyticsEvent implements WhitelabelEventWithPayload<TrackAnalyticsEventPayload> {
    payload: TrackAnalyticsEventPayload;
    type: string;
    static readonly TYPE: string = 'tracking-track-event';

    constructor(payload: TrackAnalyticsEventPayload) {
        this.type = TrackAnalyticsEvent.TYPE;
        this.payload = payload;
    }
}

class TrackWebTrackingEvent implements WhitelabelEventWithPayload<TrackWebTrackingEventPayload> {
    payload: TrackWebTrackingEventPayload;
    type: string;
    static readonly TYPE: string = 'track-web-event';

    constructor(payload: TrackWebTrackingEventPayload) {
        this.type = TrackWebTrackingEvent.TYPE;
        this.payload = payload;
    }
}

function getWebTrackingEventBody(payload: TrackWebTrackingEventPayload) {
    switch (payload.type) {
        case ALERT_BUTTON_CLICKED_EVENT_TYPE:
            const alertButtonClickedEventData: AlertButtonClickedEventPayload = {
                section: payload.section,
                sectionId: payload.section,
                sectionName: payload.sectionName,
                eventType: payload.type,
                serpUrl: payload.serpUrl,
            };
            return alertButtonClickedEventData;

        case SEARCH_SAVED_EVENT_TYPE:
            const searchSavedEventData: SearchSavedEventPayload = {
                section: payload.section,
                sectionId: payload.section,
                sectionName: payload.sectionName,
                eventType: payload.type,
                serpUrl: payload.serpUrl,
            };
            return searchSavedEventData;
        case WEB_PUSH_ALLOWED_EVENT_TYPE:
            const webPushAllowedEventData: WebPushAllowedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                section: payload.section,
                pageViewId: payload.pageViewId,
            }
            return webPushAllowedEventData;
        case FILTERS_MODAL_OPENED_EVENT_TYPE:
            const filtersModalOpenedEventData: FiltersModalOpenedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
            };
            return filtersModalOpenedEventData;
        case FILTERS_UPDATED_EVENT_TYPE:
            const filtersUpdatedEventData: FiltersUpdatedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                ...payload.filters,
            };
            return filtersUpdatedEventData;
        case SECTION_CLICKED_EVENT_TYPE:
            const sectionClickedEventData: SectionClickedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                sectionName: payload.sectionName,
                value: payload.value,
                sectionId: payload.section,
                pageViewId: payload.pageViewId!,
            };
            return sectionClickedEventData;
        case MARKET_STATS_CLICKED_EVENT_TYPE:
            const marketStatsClickedEventData: MarketStatsClickedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                sectionId: payload.section!,
                sectionName: payload.sectionName!,
                pageViewId: payload.pageViewId!,
                operationType: payload.filters["operationType"],
                propertyType: payload.filters["propertyType"],
                geoId: payload.filters["geoId"],
                rooms: payload.filters["rooms"],
                alternativeGeoId: payload.filters["alternativeGeoId"],
                amenity: payload.filters["amenity"],
                stratum: payload.filters["stratum"],
            };
            return marketStatsClickedEventData;
        case MARKET_STATS_VIEWED_EVENT_TYPE:
            const marketStatsViewedEventData: MarketStatsViewedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                sectionId: payload.section!,
                sectionName: payload.sectionName!,
                pageViewId: payload.pageViewId!,
                operationType: payload.filters["operationType"],
                propertyType: payload.filters["propertyType"],
                geoId: payload.filters["geoId"],
            };
            return marketStatsViewedEventData;
        case SHARE_CLICKED_EVENT_TYPE:
            const shareTrackingProperties = payload.filters as ShareTrackingProperties;
            const shareClickedEventData: ShareClickedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                sectionId: payload.section!,
                pageViewId: payload.pageViewId!,
                listingId: shareTrackingProperties.id,
                sectionName: payload.sectionName!,
                pageViewTypeId: shareTrackingProperties.pageViewTypeId,
            };
            return shareClickedEventData;
        case FAVORITE_ADDED_EVENT_TYPE:
        case FAVORITE_REMOVED_EVENT_TYPE:
            const favoriteAddedEventData: FavoriteClickedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                sectionId: payload.section!,
                pageViewId: payload.pageViewId!,
                listingId: payload.filters["id"],
                sectionName: payload.sectionName!,
                pageViewTypeId: payload.filters["pageViewTypeId"],
            };
            return favoriteAddedEventData;
        case PERMISSION_SEND_COMMUNICATIONS:
            const permissionSendCommunicationsEventData: PermissionSendCommunicationsEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                pageViewId: payload.pageViewId!,
                value: payload.value!,
            };
            return permissionSendCommunicationsEventData;
        /*case MARKET_STATS_DROPDOWN_CLICKED_EVENT_TYPE:
            const marketStatsDropdownClickedEventData: MarketStatsDropdownClickedEventPayload = {
                eventType: payload.type,
                serpUrl: payload.serpUrl,
                sectionId: payload.section!,
                sectionName: payload.sectionName!,
                pageViewId: payload.pageViewId!,
                operationType: 'TODO',
                propertyType: 'TODO',
                geoId: 'TODO',
                previousGeoId: 'TODO',
                selectedGeoId: 'TODO',
                previousPriceEvolutionType: 'TODO',
                selectedPriceEvolutionType: 'TODO',
            };
            return marketStatsDropdownClickedEventData;
        */
        default:
            return {};
    }
}

export function trackWebTrackingEventHandler(event: WhitelabelEvent) {
    const { payload } = event as TrackWebTrackingEvent;

    // TODO: review duplicated logic
    if (payload.type === "SearchSaved" && payload.section == null) {
        return;
    }
    if ([
        MARKET_STATS_DROPDOWN_CLICKED_EVENT_TYPE
    ].includes(payload.type)) {
        return;
    }

    eventBus.emit(new PostEvent({
        url: '/api/tracking/track',
        body: getWebTrackingEventBody(payload),
    }));
}

export function trackWebTrackingEvent(payload: TrackWebTrackingEventPayload) {
    // TODO: review duplicated logic
    if (payload.type === "SearchSaved" && payload.section == null) {
        return;
    }
    if ([
        MARKET_STATS_DROPDOWN_CLICKED_EVENT_TYPE
    ].includes(payload.type)) {
        return;
    }

    const httpPostEvent = new PostEvent({
        url: '/api/tracking/track',
        body: getWebTrackingEventBody(payload)
    });
    return httpPostEventHandler(httpPostEvent);
}

export function trackAnalyticsEventHandler(event: WhitelabelEvent) {
    const { payload: { name }} = event as TrackAnalyticsEvent;
    if (name == null) {
        return;
    }

    (tracker as GoogleTracking).trackEvent(name);
}

function init(tr: GoogleTracking | object = GA4Tracking, ev: any = EventBus.getInstance()) {
    if (isInitiated) {
        return;
    }
    tracker = tr;
    eventBus = ev;
    eventBus.subscribe(TrackAnalyticsEvent.TYPE, trackAnalyticsEventHandler);
    eventBus.subscribeWithId(TrackWebTrackingEvent.TYPE, {
        id: 'tracking-track-web-event',
        callback: trackWebTrackingEventHandler
    });
    isInitiated = true;
}

export default {
    TrackAnalyticsEvent,
    TrackWebTrackingEvent: TrackWebTrackingEvent,
    init,
}
